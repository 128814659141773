import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import ProfileFilterSidebar from "../components/Sidebar/ProfileFilterSidebar";
import { Select } from "../components/Core";
import axios from "axios";
import axiosConfig from '../axiosConfig';
import { useFilterSearch } from '../context/FilterSearchContext';
import GlobalContext from "../context/GlobalContext";

import iconL from "../assets/image/svg/icon-loaction-pin-black.svg";
import iconS from "../assets/image/svg/icon-suitecase.svg";
import network from "../../constants/Network";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';
// import { borderRadius, height } from "styled-system";

const defaultCountries = [
  { value: "sp", label: "Singapore" },
  { value: "bd", label: "Bangladesh" },
  { value: "usa", label: "United States of America" },
  { value: "uae", label: "United Arab Emirates" },
  { value: "pk", label: "Pakistan" },
];

const ProfileListing = () => {

  const { hrFilterData = {} } = useFilterSearch() || {};

  const [showMore, setShowMore] = useState([]);

  const toggleShowMore = (id) => {
    if (showMore.includes(id)) {
      setShowMore(showMore.filter(i => i !== id));
    } else {
      setShowMore([...showMore, id]);
    }
  };

  const imageBaseUrl = `${network.serverip}/images/`;

  const [search, setSearch] = useState('');
  const [count, setCount] = useState(10);
  const [totalProfileCount, setTotalProfileCount] = useState(0);
  const [resultsFor, setResultsFor] = useState('');
  const isMountedRef = useRef(false);
  const gContext = useContext(GlobalContext);
  const { user } = useContext(GlobalContext);

  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  const handleSearch = async (event) => {
    event.preventDefault();
    setCount(10);
    setShowMore([]);
    await fetchProfiles(hrFilterData, search, 10);
  };

  const handleLoadMore = async () => {
    try {
      const newCount = count + 10;
      setCount(newCount);
      await fetchProfiles(hrFilterData, search, newCount);
    } catch (error) {
      console.error('Error loading more profiles:', error);
    }
  };

  const [profiles, setProfiles] = useState([]);

  const fetchProfiles = async (hrFilterData, search, count) => {
    try {

      if (!userId) {
        console.log('No user found for profiles fetching');
        return
      }

      const response = await axios.post(`${network.serverip}/api/profilelist-forweb`, { hrFilterData, search, count, userId });
      if (response.data.success && isMountedRef.current) {
        setProfiles(response.data.data);
        setTotalProfileCount(response.data.total);
        setResultsFor(search);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setProfiles([]);
          setTotalProfileCount(0);
          setResultsFor(search);
        }
        console.error('Failed to fetch profiles');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setProfiles([]);
        setTotalProfileCount(0);
        setResultsFor(search);
      }
      console.error('Error fetching profiles:', error);
    }
  };

  const handleProfileSaveOrUnsave = async (profileId) => {
    try {

      if (!userId) {
        gContext.toggleSignInModal();

        return
      }

      if (userId && profileId) {
        const response = await axiosConfig.post(`/saveOrUnsaveProfile-web`, { userId, profileId });

        if (response.data.success && isMountedRef.current) {

          setProfiles((prevState) =>
            prevState.map((profile) =>
              profile.id === profileId
                ? { ...profile, isSaved: profile.isSaved === 1 ? 0 : 1 }
                : profile
            )
          );

          console.log('Profile saved / unsaved');
        } else {
          if (isMountedRef.current) {
            console.log('Failed to save / unsave profile');
          }
          console.error('Failed to save / unsave profile:', response.data.message);
        }
      } else {
        console.error('userId or profileId is missing');
      }
    } catch (error) {
      console.error('Failed to save/ unsave profile:', error);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchProfiles(hrFilterData, '', 10);
    };

    fetchData();
  }, [hrFilterData, userId]);

  const styles = ProfileListingStyling();

  return (
    <>
      <PageWrapper>
        <div className="bg-default-1 pt-32 pt-lg-28 pb-13 pb-lg-25">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-3 col-lg-4">
                <ProfileFilterSidebar />
              </div>
              {/* <!-- Main Body --> */}
              <div className="col-12 col-xl-9 col-lg-8">
                {/* <!-- form --> */}
                <form className="search-form" onSubmit={handleSearch}>
                  <div className="filter-search-form-2 search-1-adjustment bg-white rounded-sm shadow-7 pr-6 py-6 pl-6">
                    <div className="filter-inputs">
                      <div className="form-group position-relative w-lg-45 w-xl-40 w-xxl-45">
                        <input
                          className="form-control focus-reset pl-13"
                          type="text"
                          id="search"
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                          placeholder="Search"
                        />
                        <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                          <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                        </span>
                      </div>
                      {/* <!-- .select-city starts --> */}
                      {/* <div className="form-group position-relative w-lg-55 w-xl-60 w-xxl-55">
                        <Select
                          options={defaultCountries}
                          className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100"
                          border={false}
                        />
                        <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                          <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                        </span>
                      </div> */}
                      {/* <!-- ./select-city ends --> */}
                    </div>
                    <div className="button-block">
                      <button
                        className="btn btn-primary line-height-reset h-100 btn-submit w-100 text-uppercase"
                        type="submit"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
                <div className="pt-12">
                  <div className="d-flex align-items-center justify-content-between mb-6">
                    {resultsFor ? (
                      <h5 className="font-size-4 font-weight-normal text-gray">
                        <span className="heading-default-color">{totalProfileCount}</span>
                        {totalProfileCount === 1
                          ? ` result for `
                          : ` results for `}
                        <span className="heading-default-color">{resultsFor}</span>
                      </h5>
                    ) : (
                      <h5 className="font-size-4 font-weight-normal text-gray">
                        <span className="heading-default-color">{totalProfileCount}</span>
                        {" "} {"Profile(s) found"} {" "}
                      </h5>
                    )
                    }
                    <div className="d-flex align-items-center result-view-type">
                      <Link
                        to="/profilelisting"
                        className="heading-default-color pl-5 font-size-6 hover-text-hitgray active"
                      >
                        <i className="fa fa-list-ul"></i>
                      </Link>
                      <Link
                        to="/profilelisting-grid"
                        className="heading-default-color pl-5 font-size-6 hover-text-hitgray"
                      >
                        <i className="fa fa-th-large"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="mb-8">
                    {profiles.map((profile, index) => (
                      <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 " style={{ marginBottom: "20px" }} key={index}>
                        <div className="row media align-items-center">
                          <div className="col-12 d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <button
                                onClick={() => gContext.toggleImageViewModal(profile?.image)}
                                style={styles.imageViewButton}
                              >
                                <div className="square-72 d-flex align-items-center" style={styles.imageContainer}>
                                  <img
                                    src={profile && profile.image ? imageBaseUrl + profile.image : imageBaseUrl + "profile_default_photo.png"}
                                    alt=""
                                    style={styles.profileImage}
                                  />
                                </div>
                              </button>
                            </div>
                            <div className="flex-grow-1 ml-3">
                              <div className="w-100  d-flex justify-content-between">
                                <div className="flex-grow-1 ml-3">
                                  <h3 className="mb-0">
                                    <Link to={`/profile-details-view/?id=${profile.id}`}
                                      className="font-size-6 heading-default-color">
                                      {profile && profile.name ? profile.name : ''}
                                    </Link>
                                  </h3>
                                </div>
                                <div className="flex-shrink-0">
                                  {profile?.created_at && (
                                    <div className="text-right">
                                      <p className="font-size-3 mb-0" style={styles.createdDateBox}>
                                        {new Date(profile.created_at).toLocaleDateString('default', {
                                          day: '2-digit',
                                          month: 'short',
                                          year: 'numeric'
                                        }).replace(/ /g, '-')}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <ul className="d-flex list-unstyled flex-wrap align-items-center">
                                <li className="mt-2 mr-8 font-size-3 font-weight-bold text-black-2 d-flex align-items-center">
                                  <span className="mr-2">
                                    <FontAwesomeIcon icon={faUserMd} className="text-success" />
                                  </span>
                                  <span className="">
                                    {profile && profile.role ? profile.role : ''}
                                  </span>
                                </li>
                                {/* <li className="mt-2 font-size-3 d-flex align-items-center">
                                  <span className="mr-2 d-flex align-items-center">
                                    <img src={iconS} alt="" style={{ height: '1.2em', width: 'auto' }} />
                                  </span>
                                  <span className="">
                                    {job && job.job_type && job.job_type.title ? job.job_type.title : ''}
                                  </span>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row pt-2">
                          <div className="col-md-12">
                            <p className="mb-2">
                              {profile && profile.about_me ? (
                                <span className=" font-size-4 text-black-2">
                                  {((showMore.includes(profile.id)) || profile.about_me.length <= 150)
                                    ? `${profile.about_me} `
                                    : `${profile.about_me.slice(0, 150)}... `}
                                  {profile.about_me.length > 150 && (
                                    // <span onClick={() => toggleShowMore(job.id)} style={styles.showMoreButton}>
                                    //   {showMore.includes(job.id) ? 'Show less' : 'Show more'}
                                    // </span>
                                    <Link to={`/profile-details-view/?id=${profile.id}`} style={styles.showMoreButton}>
                                      {'Show more'}
                                    </Link>
                                  )}
                                </span>
                              ) : (
                                null
                              )}
                            </p>
                          </div>
                        </div>
                        {/* <div className="w-100">
                          {job?.posted_by?.name && (
                            <p className="font-size-3 text-gray mb-0">
                              {`Posted by: ${job.posted_by.name}`}
                            </p>
                          )}
                        </div> */}
                        <div className="row pt-4">
                          <div className="col-md-12 d-flex justify-content-between align-items-center">
                            {profile && (profile.city || profile.state) ? (
                              <p className="font-size-4 text-black-2">
                                <span className="mr-4">
                                  <img src={iconL} alt="" />
                                </span>
                                <span className="font-weight-semibold">
                                  {profile.city || ''}{profile.city && profile.state ? ', ' : ''}{profile.state || ''}
                                </span>
                              </p>
                            ) : (
                              <p></p>
                            )}
                            <div>
                              <button
                                className="btn text-success h-px-48 rounded-3 mb-5 px-5">
                                <FontAwesomeIcon icon={faComments} className="font-weight-bold mr-4 font-size-4" />{" "}
                                Chat
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="text-center pt-5 pt-lg-13">
                    {profiles.length > 0 && totalProfileCount > profiles.length ? (
                      <button onClick={handleLoadMore}
                        className="btn btn-green text-uppercase btn-medium rounded-3"
                      >
                        <span>
                          Load More <i className="fas fa-arrow-down ml-2"></i>
                        </span>
                      </button>
                    ) : null}

                    {profiles.length === 0 && (
                      <span className="heading-default-color font-weight-bold font-size-3">
                        No profiles found
                      </span>
                    )}
                  </div>
                </div>
                {/* <!-- form end --> */}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );


};
export default ProfileListing;


function ProfileListingStyling() {
  const styles = {
    showMoreButton: {
      display: 'inline-block',
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    imageContainer: {
      backgroundColor: '#f5f5f5',
      aspectRatio: '4 / 4',
      overflow: 'hidden',
    },
    profileImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    saveButton: {
      border: 'none',
      backgroundColor: 'transparent',
    },

    // loadMoreButton: {
    //   border: 'none',
    //   background: 'none',
    // },

    salaryBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
    },

    createdDateBox: {
      backgroundColor: '#f0f0f0',
      padding: '5px',
      borderRadius: '5px',
    },

    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
  },

  };

  return styles;
}
